import { Switch, Route } from "react-router-dom";
import NoteIndex from "./NoteIndex";
import NoteUpload from "./NoteUpload";

export default function Notes() {
    return <Switch>
      <Route path="/notes/upload">
        <NoteUpload />
      </Route>
      <Route path="/notes">
        <NoteIndex />
      </Route>
    </Switch>
}