import PictureUploadLink from "./PictureUploadLink.js";
import { useParams } from "react-router-dom";
import { getImage } from "../util";
import { useState, useEffect } from "react";

export default function PictureShow() {
    const [image, setImage] = useState(undefined);
    
    const { id } = useParams();
    
    useEffect(() => {
        getImage(id).then( image => {
            setImage(image);
        })
    })
    
    return <div style={{minHeight:"90vh"}}>
        <PictureUploadLink />
        {image ?
            <div style={{height:"100%",width:"100%"}}>
                <img src={image.src} alt="" style={{width:"100%",height:"100%",objectFit:"contain"}}/>
            </div>
        :
            <span>Loading...</span>
        }
    </div>
}