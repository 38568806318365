import { useState } from "react";
import $ from "jquery";
import { darkColor, lightColor, accentColor } from "../colors";

const fetchGifs = (query) => {
    return $.ajax(`https://g.tenor.com/v1/search?key=YFKAXOAQKMMV&q=${encodeURIComponent(query)}&media_filter=minimal&limit=10`)
}

export default function GifSearch({state, setState}) {
    const [query, setQuery] = useState("");
    const [gifs, setGifs] = useState([]);
    const selectedGif = state.gif;
    
    const handleSubmit = e => {
        e.preventDefault();
        fetchGifs(query).then(({results}) => {
            const gifs = results.map(({media}) => ({tiny: media[0].tinygif.url, gif: media[0].gif.url}));
            setGifs(gifs)
        })
    };
    
    const handleChange = e => {
        setQuery(e.target.value);
    };
    
    return <div>
        <div style={{marginLeft:"auto",marginRight:"auto", display:"block", width:"350px", color:darkColor}}>
            <label style={{width:"100px", color:darkColor}}>Gif Search:<input type="text" onChange={handleChange} value={query} style={{marginLeft:"5px",backgroundColor:accentColor}}/></label>
            <button onClick={handleSubmit} style={{marginLeft: "10px",width:"80px", padding:"5px 10px", backgroundColor:darkColor, border:`2px solid ${lightColor}`, borderRadius:"8px", textDecoration:"none", color:accentColor,marginBottom:"20px"}}>Search</button>
        </div>
        <input type="submit" value="Submit" style={{marginLeft:"auto",marginRight:"auto",display:"block", width:"160px", padding:"10px 20px", backgroundColor:darkColor, border:`2px solid ${lightColor}`, borderRadius:"8px", textDecoration:"none", color:accentColor,marginBottom:"20px"}}/>
        <div style={{display:"flex",flexWrap:"wrap"}}>
        {gifs.map((gif, i) => {
            return <img key={i} src={gif.tiny} alt="" style={gif.gif === selectedGif ? {borderColor:darkColor,boxShadow:`0 0 10px ${darkColor}`,margin:"20px",alignSelf:"center"} : {margin:"20px",alignSelf:"center"}}onClick={() => {
                setState({...state, gif: gif.gif});
            }}
            />
        })}
        </div>
    </div>
}