import { useEffect, useState } from "react";
import { getImages } from "../util";
import PictureItem from "../pictures/PictureItem";

export default function RotatingImages() {
    const [images, setImages] = useState(undefined);
    const [idx, setIdx] = useState(-1);
    
    
    useEffect(() => {
        let intervalID;
        let i = idx;
        
        const receiveImages = images => {
            images.sort((a, b) => b.createdAt - a.createdAt);
            
            setImages(images);
            
            const updateIdx = () => {
                i = (i + 1) % images.length;
                setIdx(i);
            }
            
            intervalID = setInterval(updateIdx, 3000)
        };
        
        getImages().then(receiveImages);
        
        return () => {
            clearInterval(intervalID);
        }
    },[]);
    
    if (images) {
        return <div style={{display:"Flex",justifyContent:"center"}}>
            <PictureItem {...images[idx]} />
        </div>
    } else {
        return <></>
    }
}