import { Switch, Route } from "react-router-dom";
import PictureIndex from "./PictureIndex";
import PictureShow from "./PictureShow";
import PictureUpload from "./PictureUpload";

export default function Pictures() {
    return <Switch>
      <Route path="/pictures/upload">
        <PictureUpload />
      </Route>
      <Route path="/pictures/:id">
        <PictureShow />
      </Route>
      <Route path="/pictures">
        <PictureIndex />
      </Route>
    </Switch>
}