import RotatingImages from "./RotatingImages";

export default function Home() {
    return <div>
     <h1>Welcome!</h1>
     <p>Welcome to the site, and thanks for celebrating with us! Here's the plan for the day:</p>
     <p><strong>1PM:</strong> Ceremony starts at Wagner Cove.</p>
     <p><strong>1:15PM-ish:</strong> Celebrate/chill in Central Park with some champagne and snacks. Bring a picnic blanket if you can!</p>
     <p><strong>???PM:</strong> Bar hopping </p>
     <p><strong>7PM:</strong> Dinner at Jackdaw. Feel free to bring a +1! </p>
     <h2>Gifts</h2>
     <p>To keep things simple, <strong>please don't buy any gifts.</strong> In place of gifts, we just ask everyone to cover the costs of their own drinks/food (except for the champagne/snacks in Central Park).</p>
     <h2>Pictures</h2>
     <p>If you take any pictures today, upload them to this site so everyone can see! The pictures can be of any festivities from today, even if we aren't in them. Feel free to upload past pictures with us, too, if you have any other memories you'd like to share!</p>
     <h2>Notes</h2>
     <p>You can also leave a note for us and everyone else to see! The note can be a funny story, memory, advice, etc. Feel free to be creative!</p>
     < RotatingImages />
    </div>
}