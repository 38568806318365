import NoteUploadLink from "./NoteUploadLink.js";
import NoteItem from "./NoteItem";
import { useState, useEffect } from "react";
import { getNotes } from "../util";

export default function NoteIndex() {
    const [notes, setNotes] = useState([]);
    
    const receiveNotes = e => {
        e.data.listNotes.items.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
        setNotes(e.data.listNotes.items)
    }
    
    useEffect(() => {
        getNotes(receiveNotes)
    }, [])
    
    
    return <div>
        <NoteUploadLink />
        <div>
            {notes.map((note, idx) => <NoteItem key={idx} id={idx} {...note} />)}
        </div>
    </div>
}