import { useState } from "react";
import { uploadPictures } from "../util";
import { useHistory } from "react-router-dom";
import { darkColor, lightColor, accentColor } from "../colors"

export default function PictureUpload() {
    const [state, setState] = useState({
        selectedFiles: [],
        progressInfos: [],
        message: [],
        fileInfos: [],
        author: "",
    });
    
    const [isUploading, setUploading] = useState(false)
    
    const history = useHistory();
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setUploading(true);
        uploadPictures(state.selectedFiles, state.author).then(() => history.push("/pictures"));
    };
    const handleUpload = (e) => {console.log(e.target.files);setState({...state, selectedFiles: e.target.files, progressInfos: []})};
    const handleNameChange = (e) => {setState({...state, author: e.target.value})};
    
    const getImagePreviews = () => {
        const imgs = [];
        for (let img of state.selectedFiles) {
            imgs.push(
                <img src={URL.createObjectURL(img)} alt="" style={{width:"100px",height:"100px",objectFit:"contain"}}/>    
            )
        }
        return imgs;
    }
    
    return <div>
        <form onSubmit={handleSubmit} style={{paddingTop:"20px"}}>
            <label style={{marginLeft:"auto",marginRight:"auto", display:"block", width:"300px", color:darkColor}}>Uploader name:<input type="text" onChange={handleNameChange} value={state.author} style={{marginLeft:"10px",backgroundColor:accentColor}}/></label>
            <br/>
            <label style={{marginLeft:"auto",marginRight:"auto",display:"block", width:"120px", padding:"10px 20px", backgroundColor:darkColor, border:`2px solid ${lightColor}`, borderRadius:"8px", textDecoration:"none", color:accentColor,marginBottom:"20px"}}>Browse Photos<input type="file" accept="image/*" multiple={true} onChange={handleUpload} style={{display:"none"}}/></label>
            <br/>
            <div style={{display:"flex",flexWrap:"wrap", justifyContent:"center"}}>
                {getImagePreviews()}
            </div>
            <br/>
            <input type="submit" value="Upload" style={{marginLeft:"auto",marginRight:"auto",display:"block", width:"160px", padding:"10px 20px", backgroundColor:darkColor, border:`2px solid ${lightColor}`, borderRadius:"8px", textDecoration:"none", color:accentColor,marginBottom:"20px"}}/>
            {isUploading ? <span> Uploading... </span> : <div></div> }
        </form>
    </div>;
}