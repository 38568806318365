import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import NavBar from "./NavBar";
import Pictures from "./pictures/Pictures";
import Notes from "./notes/Notes";
import Home from "./home/Home";
import { bodyColor, accentColor } from "./colors"

function App() {
  document.body.style.backgroundColor = accentColor;
  return (
    <Router >
      < NavBar />
      <div style={{width:"80%",backgroundColor:bodyColor, margin:"auto", paddingLeft:"10px",paddingRight:"10px",minHeight:"90vh"}}>
        <Switch>
          <Route path="/pictures">
            <Pictures />
          </Route>
          <Route path="/notes">
            <Notes />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
