import { NavLink } from "react-router-dom"
import { darkColor, accentColor } from "./colors"
export default function NavBar() {
    return <nav style={{display:"flex", justifyContent:"space-between", backgroundColor:darkColor, color:accentColor,margin:0,borderBottom:`1px solid ${accentColor}`}}>
        <span style={{flexGrow:0, paddingLeft: "20px", marginTop:"auto", marginBottom:"auto",fontFamily:"'Ephesis', cursive",fontWeight:"bold",fontSize:"25px"}}>Dane & Caitlyn</span>
        <ul style={{display:"flex", justifyContent:"space-around", listStyle: "none", flexGrow:1}}>
            <li><NavLink exact to="/" activeStyle={{fontWeight:"bold"}} style={{color:"inherit",textDecoration:"none"}}>Home</NavLink></li>
            <li><NavLink to="/pictures" activeStyle={{fontWeight:"bold"}} style={{color:"inherit",textDecoration:"none"}}>Pictures</NavLink></li>
            <li><NavLink to="/notes" activeStyle={{fontWeight:"bold"}} style={{color:"inherit",textDecoration:"none"}}>Notes</NavLink></li>
        </ul>
    </nav>
}