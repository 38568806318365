import { useState } from "react";
import { createNewNote } from "../util"
import { useHistory } from 'react-router-dom';
import { darkColor, accentColor } from "../colors";
import GifSearch from "./GifSearch";

export default function NoteUpload() {
    const [state, setState] = useState({
        text: "",
        author: "",
        gif: "",
    })
    const history = useHistory();
    
    const handleSubmit = (e) => {
        e.preventDefault();
        createNewNote(state).then(e => history.push("/notes"))
    };
    const handleTextChange = (e) => {setState({...state, text: e.target.value})};
    const handleNameChange = (e) => {setState({...state, author: e.target.value})};
    
    return <div>
        <form onSubmit={handleSubmit}  style={{paddingTop:"20px"}}>
            <label style={{marginLeft:"auto",marginRight:"auto", display:"block", width:"300px", color:darkColor}}>Uploader name:<input style={{marginLeft:"10px",backgroundColor:accentColor}} type="text" onChange={handleNameChange} value={state.author}/></label>
            <br/>
            <textarea onChange={handleTextChange} value={state.text} style={{marginLeft:"auto",marginRight:"auto", display:"block", width:"300px", maxWidth:"80%", height:"200px", color:darkColor,backgroundColor:accentColor}}/>
            <br/>
            <GifSearch state={state} setState={setState} />
        </form>
    </div>
}