import { darkColor } from "../colors";

export default function NoteItem({id, text, author, gif}) {
    return <span style={{paddingLeft:"10px", marginBottom: "50px"}}>
        { gif?.length ?
            <img src={gif} alt="" style={{width:"400px",maxWidth:"100%",height:"300px",objectFit:"contain"}}/>
        :
            <div></div>
        }
        <p style={{color:darkColor}}>{text}</p>
        <p style={{color:darkColor}}>{"~"+author}</p>
    </span>
}