import Amplify, { API, graphqlOperation, Storage } from "aws-amplify";

import awsconfig from "./aws-exports";
import { createNote, createImage } from "./graphql/mutations";
import { listNotes, listImages } from "./graphql/queries";

Amplify.configure(awsconfig);

export async function createNewNote(note) {
  return API.graphql(graphqlOperation(createNote, { input: note }));
}

export function getNotes(receiveNotes) {
    return API.graphql(graphqlOperation(listNotes)).then(receiveNotes)
}

function uploadPicture(file, author) {
    const hashed = hash(file.name).toString();
    return Storage.put(hashed, file).then(({ key }) => {console.log(key);savePictureData({
        author,
        key,
    })})
}

function randomSalt() {
    return (Math.random() + 1).toString(36).substring(2);
}

function hash(str) {
  const salted = str + randomSalt();
  var hash = 0, i, chr;
  if (salted.length === 0) return hash;
  for (i = 0; i < salted.length; i++) {
    chr   = salted.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export function uploadPictures(files, author) {
    const promises = [];
    for (let i = 0; i < files.length; i++) {
        promises.push(uploadPicture(files[i], author));
    }
    return Promise.all(promises);
}

function savePictureData(data) {
    return API.graphql(graphqlOperation(createImage, { input: data }));
}

export function getImages() {
    return API.graphql(graphqlOperation(listImages)).then(getImageURLs);
}

export function getImage(key) {
    return getImageURL({key}, true)
}

const getImageURLs = resp => {
    const images = resp.data.listImages.items.map(getImageURL, false);
    return Promise.all(images);
};

const getImageURL = async (image, idx, arr, justURL = false) => {
    const src = await Storage.get(image.key);
    const newImage = {
        src,
        id: image.key
    }
    if (!justURL) {
        newImage.author = image.author;
        newImage.createdAt = Date.parse(image.createdAt);
    }
    return newImage;
};