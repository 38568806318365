import PictureUploadLink from "./PictureUploadLink.js";
import PictureItem from "./PictureItem";
import { useState, useEffect } from "react";
import { getImages } from "../util";

export default function PictureIndex() {
    const [images, setImages] = useState([]);
    
    const receiveImages = images => {
        images.sort((a, b) => b.createdAt - a.createdAt)
        setImages(images)
    };
    
    useEffect(() => {
        getImages().then(receiveImages);
    },[]);
    
    return <div>
        <PictureUploadLink />
        <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
            {images.map((pic, idx) => <PictureItem key={idx} id={idx} {...pic} />)}
        </div>
    </div>;
}